<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="450">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('edit')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Update Merchant Reward Slot {{modalInfo.SlotGroupID}}
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Operator"
                    v-model="form.operator.selected"
                    :items="form.operator.items"
                    @change="execFilter('operator')"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="form.operator.rule"
                    :loading="form.operator.loader"
                    hide-details="auto"
                    :disabled="readonly || disablefield_based_on_TPQ"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.CarParkUserInputCode"
                        label="CP Code"
                        required
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Car Park *"
                    v-model="form.carpark.selected"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    hide-details="auto"
                    :disabled="readonly || disablefield_based_on_TPQ"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.SlotGroupID"
                        label="Slot Group ID"
                        required
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.slotname.item"
                        :rules="form.slotname.rule"
                        label="Reward Slot Name *"
                        required
                        hide-details="auto"
                        :disabled="readonly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-row dense no-gutters>
                        <v-col cols="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form.duration.item"
                                v-mask="'#########'"
                                :rules="form.duration.rule"
                                label="Slot Duration (numbers) *"
                                required
                                hide-details="auto"
                                :disabled="readonly || disablefield_based_on_TPQ"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                            dense
                            outlined
                            placeholder="Select"
                            label="Slot Type *"
                            v-model="form.slottype.selected"
                            :items="form.slottype.items"
                            item-text="name"
                            item-value="id"
                            :rules="form.slottype.rule"
                            hide-details="auto"
                            :disabled="readonly || disablefield_based_on_TPQ"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="12">
                    <dpicker :key="counter" :datetime="form.startdate" :mode="'date'" @date="dateValStartTime" :close="dialog_local" :readonly="readonly || disablefield_based_on_TPQ" :place="true" label="Start Selling Date *" :useforedit="true" :width="'98px'"></dpicker>
                </v-col>
                <v-col cols="12" md="12">
                    <dpicker :key="counter2" :datetime="form.enddate" :mode="'date'" @date="dateValEndTime" :close="dialog_local" :readonly="readonly || disablefield_based_on_TPQ" :place="true" label="Stop Selling Date *" :useforedit="true" :width="'98px'"></dpicker>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.totalqty.item"
                        :rules="form.totalqty.rule"
                        label="Total Slot Quantity *"
                        required
                        hide-details="auto"
                        :disabled="readonly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.SlotsLeft"
                        label="Remain Slot Quantity"
                        required
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.TotalPurchaseQuantity"
                        label="Purchased Slot Quantity"
                        required
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.unitprice.item"
                        @keyup.prevent="calcTotalAmount()"
                        :rules="form.unitprice.rule"
                        label="Slot Unit Price (RM) *"
                        required
                        hide-details="auto"
                        :disabled="readonly || disablefield_based_on_TPQ"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Promo Voucher</span>
                    <v-switch
                    :color="readonly || disablefield_based_on_TPQ ? 'grey' : 'success'"
                    class="col-12 pl-0"
                    v-model="form.promovoucher"
                    @change="calcTotalAmount()"
                    :disabled="readonly || disablefield_based_on_TPQ"
                    :hint="form.promovoucher == 1 ? 'Promo Voucher feature is enable for this Reward Slot.' : 'Promo Voucher feature is disable for this Reward Slot.'"
                    persistent-hint
                    >
                    <template v-slot:label class="checkbox-role"><span style="width: 100%" @click="changeOrder">{{form.promovoucher == 1 ? 'Yes' : 'No'}}</span></template>
                    </v-switch>
                </v-col>
                <v-col cols="12" md="12" v-if="form.promovoucher == 1">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.voucherqty.item"
                        @keyup.prevent="calcTotalAmount()"
                        :rules="form.voucherqty.rule"
                        label="Total Promo Voucher Quantity / slot *"
                        required
                        hide-details="auto"
                        :disabled="readonly || disablefield_based_on_TPQ"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" v-if="form.promovoucher == 1">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.voucherunitprice.item"
                        @keyup.prevent="calcTotalAmount()"
                        :rules="form.voucherunitprice.rule"
                        label="Voucher Unit Price (RM) *"
                        required
                        hide-details="auto"
                        :disabled="readonly || disablefield_based_on_TPQ"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" v-if="form.promovoucher == 1">
                    <v-text-field
                        dense
                        outlined
                        v-model="countTotalVoucherPrice"
                        label="Promo Voucher Total Price (RM)"
                        required
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.servicefees.item"
                        label="Total Service Fees (RM)"
                        required
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.totalprice.item"
                        :rules="form.totalprice.rule"
                        label="Total Price (RM) *"
                        required
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.DateCreated"
                        label="Created Date"
                        required
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.DateUpdated"
                        label="Updated Date"
                        required
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Public Visible</span>
                    <v-switch
                    color="success"
                    class="col-12 pl-0"
                    v-model="form.publicvisible"
                    :disabled="readonly"
                    :hint="form.publicvisible == 1 ? 'Available and visible for corporate user to purchase.' : 'Not available for corporate user to purchase.'"
                    persistent-hint
                    >
                    <template v-slot:label class="checkbox-role"><span style="width: 100%" @click="changeOrder">{{form.publicvisible == 1 ? 'Enabled' : 'Disabled'}}</span></template>
                    </v-switch>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
    </v-snackbar>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import {ERR} from '../../assets/js/errhandle.js';
import {rule} from '../../assets/js/checkPerm';
import dpicker from "../DatePickerComponent2";
import * as moment from "moment";
import * as tz from "moment-timezone";

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo', 'readonly', 'operator', 'carpark', 'productcode'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: {
                productcode: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                slotname: {item: '', rule: []},
                duration: {item: '', rule: []},
                startdate: {item: '', itemforapi: '', rule: []},
                enddate: {item: '', itemforapi: '', rule: []},
                totalqty: {item: '', rule: []},
                unitprice: {item: '', rule: []},
                voucherqty: {item: '', rule: []},
                voucherunitprice: {item: '', rule: []},
                totalprice: {item: '', rule: []},
                operator: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                slottype: {
                    items: [
                        {id: '', name: 'Select'},
                        {id: 'week', name: 'Week(s)'},
                        {id: 'month', name: 'Month(s)'},
                        {id: 'year', name: 'Year(s)'},
                    ],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                promovoucher: false,
                publicvisible: false,
                servicefees: {item: ''}
            },

            btn_loading: false,

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            modal_loader: false,
            counter: 0,
            counter2: 1,
            disablefield_based_on_TPQ: false
        }
    },

    components: {alertbox, dpicker},

    watch: {
        dialog: {
        handler: async function(value) {
            await this.modalHandler();
            if(value == true){
                this.modal_loader = true;
                await this.handleValue();
                this.modal_loader = false;
                this.btn_loading = false;
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    computed: {
        countTotalVoucherPrice(){
            let num1 = Number(this.form.voucherunitprice.item) * Number(this.form.voucherqty.item);
            return num1.toFixed(2);
        }
    },

    methods: {
        async onRuleChange(after, before) {
            this.form.productcode.rule = [];
            this.form.operator.rule = [];
            this.form.slotname.rule = [];
            this.form.startdate.rule = [];
            this.form.enddate.rule = [];
            this.form.carpark.rule = [];
            this.form.totalqty.rule = [];
            this.form.unitprice.rule = [];
            this.form.voucherqty.rule = [];
            this.form.voucherunitprice.rule = [];
            this.form.duration.rule = [];
            this.form.slottype.rule = [];
        },

        async beforeCreateForm(flag){
            this.form.productcode.rule = [
                v => !!v || 'This is required',
            ];

            /*
            this.form.operator.rule = [
                v => !!v || 'This is required',
            ];
            */

            this.form.carpark.rule = [
                v => !!v || 'This is required',
            ];

            this.form.slottype.rule = [
                v => !!v || 'This is required',
            ];

            this.form.slotname.rule = [
                v => !!v || 'This is required',
            ];

            this.form.duration.rule = [
                v => !!v || 'This is required',
                v => Number.isInteger(Number(v)) || 'Only whole number is allowed',
            ];

            this.form.startdate.rule = [
                v => !!v || 'This is required',
            ];

            this.form.enddate.rule = [
                v => !!v || 'This is required',
            ];

            this.form.totalqty.rule = [
                v => !!v || 'This is required',
                v => Number.isInteger(Number(v)) || 'Only whole number is allowed',
            ];
            
            this.form.unitprice.rule = [
                v => !!v || 'This is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.voucherqty.rule = [
                v => !!v || 'This is required',
                v => Number.isInteger(Number(v)) || 'Only whole number is allowed',
            ];
            
            this.form.voucherunitprice.rule = [
                v => v.length != 0 || 'This is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            this.counter++;
            this.counter2++;
            this.onRuleChange();
            this.disablefield_based_on_TPQ = false;

            let operator = [];
            operator.push({name: 'Select', OperatorID: ''});
            let filteredoperator = this.operator.filter(item => item.OperatorID !== "");
            for (const [index, item] of filteredoperator.entries()){
                operator.push(item);
            }

            this.form.operator.items = operator;
            this.form.operator.selected = "";

            let carpark = [];
            //carpark.push({name: 'Select', CarParkID: ''});
            carpark.push({name: 'Select', CarParkID: ''});
            let filteredcarpark = this.carpark.filter(item => item.CarParkID !== "");
            for (const [index, item] of filteredcarpark.entries()){
                carpark.push(item);
            }

            let op = carpark.find(item => item.CarParkID == this.modalInfo.CarParkID);
            this.form.operator.selected = op.OperatorID;

            this.form.carpark.items = [];
            this.form.carpark.selected = this.modalInfo.CarParkID;

            let itm = this.carpark.filter(i => i.OperatorID == this.form.operator.selected);

            //this.form.carpark.items.push({name: 'Select', CarParkID: ''});
            this.form.carpark.items.push({name: 'Select', CarParkID: ''});

            for(let i=0; i<itm.length; i++){
                this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
            }

            let productcode = [];
            productcode.push({name: 'Select', ProductCode_Code: ''});
            let filteredproductcode = this.productcode.filter(item => item.ProductCode_Code !== "");
            for (const [index, item] of filteredproductcode.entries()){
                productcode.push(item);
            }

            this.form.productcode.items = productcode;
            this.form.productcode.selected = "";

            this.form.slottype.selected = this.modalInfo.PurchaseDurationUnit;

            if (this.modalInfo.TotalPurchaseQuantity > 0){
                this.disablefield_based_on_TPQ = true;
            }

            this.form.slotname.item = this.modalInfo.GroupName;
            this.form.duration.item = this.modalInfo.PurchaseDurationPerSlot;
            this.form.startdate.itemforapi = '';
            this.form.startdate.item = new Date(moment.utc(new Date(new Date(this.modalInfo.StartDateFromAPI))).tz('Asia/Kuala_Lumpur').format('MM/DD/YYYY hh:mm:ss A'));
            this.form.enddate.itemforapi = '';
            this.form.enddate.item = new Date(moment.utc(new Date(new Date(this.modalInfo.EndDateFromAPI))).tz('Asia/Kuala_Lumpur').format('MM/DD/YYYY hh:mm:ss A'));
            this.form.unitprice.item = this.modalInfo.CostPerSlot;
            this.form.totalqty.item = this.modalInfo.TotalSlots;
            this.form.promovoucher = this.modalInfo.HavePromoVoucher;
            this.form.publicvisible = this.modalInfo.PublicViewStatus;
            this.form.voucherunitprice.item = this.modalInfo.UnitPricePerPromoVoucher;
            this.form.voucherqty.item = this.modalInfo.PromoVoucherQuantity;
            this.form.totalprice.item = Number(this.modalInfo.GrandFinalTotal).toFixed(2);
            this.form.servicefees.item = Number(this.modalInfo.ServiceFeeFinalTotal).toFixed(2);
            this.counter++;
            this.counter2++;
        },

        handleDelete(value){
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        execFilter(typ){
            if (typ == 'operator'){
                this.form.carpark.items = [];
                this.form.carpark.selected = '';

                if (this.form.operator.selected){
                    let itm = this.carpark.filter(i => i.OperatorID == this.form.operator.selected);

                    //this.form.carpark.items.push({name: 'Select', CarParkID: ''});
                    this.form.carpark.items.push({name: 'Select', CarParkID: ''});

                    for(let i=0; i<itm.length; i++){
                        this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }

                else{
                    //this.form.carpark.items.push({name: 'Select', CarParkID: ''});
                    this.form.carpark.items.push({name: 'Select', CarParkID: ''});
                    let itm = this.carpark;
                    for(let i=0; i<itm.length; i++){
                        this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }
            }
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
                if (this.ma.modal_action_type == 'edit'){
                    this.form.flag = 'update';
                    this.form.startdate.itemforapi = moment(new Date(this.form.startdate.item).setHours(16, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm');
                    this.form.enddate.itemforapi = moment(new Date(this.form.enddate.item).setHours(15, 59, 59, 0)).format('YYYY-MM-DD HH:mm');
                    this.form.duration.item = Number(this.form.duration.item);
                    this.form.totalqty.item = Number(this.form.totalqty.item);
                    this.form.unitprice.item = Number(this.form.unitprice.item);
                    this.form.voucherqty.item = Number(this.form.voucherqty.item);
                    this.form.voucherunitprice.item = Number(this.form.voucherunitprice.item);
                    if (this.form.promovoucher !== 1 && this.form.promovoucher !== true){
                        this.form.voucherqty.item = null;
                        this.form.voucherunitprice.item = null;
                    }
                    this.form.promovoucher = this.form.promovoucher === true || this.form.promovoucher === 1 ? 1 : 0;
                    this.form.publicvisible = this.form.publicvisible === true || this.form.publicvisible === 1 ? 1 : 0;
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        dateValStartTime(value){
            this.form.startdate.item = value;
        },

        dateValEndTime(value){
            this.form.enddate.item = value;
        },

        calcTotalAmount(){
            let num1 = Number(this.form.voucherunitprice.item) * Number(this.form.voucherqty.item);
            let countTotalVoucherPrice = this.form.promovoucher == 1 ? num1.toFixed(2) : 0;
            this.form.servicefees.item = 0;
            let total = (Number(this.form.unitprice.item) + Number(countTotalVoucherPrice)) * Number(this.modalInfo.ServiceFeePercentage);
            this.form.servicefees.item = total.toFixed(2);
            console.log(Number(countTotalVoucherPrice));
            let total2 = Number(this.form.unitprice.item) + Number(countTotalVoucherPrice) + Number(total.toFixed(2));
            this.form.totalprice.item = total2.toFixed(2);
        },

        changeOrder(e) {
            e.preventDefault();
            e.stopPropagation();
        },
    }
}
</script>